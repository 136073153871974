@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
      font-family: Poppins, system-ui, sans-serif;
    }
  }

*{
  font-family: "Poppins";
}

.custom-container{
    height: auto;
    max-width: 75rem;
    width: 100%;
}

@media (min-width: 640px){
  .custom-container {
      max-width: 640px;
  }
}

@media (min-width: 768px){
  .custom-container {
      max-width: 768px;
  }
}

@media (min-width: 1024px){
  .custom-container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px){
  .custom-container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px){
  .custom-container {
    max-width: 1536px;
  }
}



/* styles for stepper */

.MuiStepLabel-label{
  font-family: "Poppins" !important;
}