@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500;1,700&family=Sen:wght@400;700;800&display=swap');
/* Roboto and San fonts */

/* Navbar styles only for below 'md' screens */

.nav-styles{
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

@media screen and (max-width: 767px){
    .nav-hidden{
        margin-top: -100%;
        opacity: 0;
        visibility: hidden;
    }
    .nav-active{
        margin-top: 0px;
        opacity: 1;
        visibility: visible;
    }
}


/* ---------------- Start Login / SignUp Components Css ---------------- */
.primary-button {
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: 'Poppins' !important;
    line-height: 1.5rem !important;
    background: #5956D6 !important;
    /* padding: 14px 104px 14px 104px !important; */
    text-transform: none !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
    border: 1px solid #5956D6 !important;
}
.primary-button:hover{
    background: #4240a0 !important;
    border-color: #4240a0 !important;
}
.primary-button:disabled{
    background-color: #E0E1E2 !important;
    color: gray !important;
    border-color: #E0E1E2 !important;
}

.secondary-button{
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: 'Poppins' !important;
    line-height: 1.5rem !important;
    text-transform: none !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
    background-color: transparent !important;
    border: 1px solid #5956D6 !important;
    color: #5956D6 !important;
}
.secondary-button:disabled{
    color: gray !important;
    border-color: gray !important;
    /* cursor: not-allowed !important;
    pointer-events: all !important; */
}
.secondary-button:hover{
    background-color: rgba(0,0,0,0.1);
}

.loginbutton, .signupbutton {
    font-size: 16px !important;
    font-weight: 400 !important;
    font-family: 'Poppins' !important;
    line-height: 20px !important;
    background: #5956D6 !important;
    padding: 14px 60px 14px 60px !important;
    border-radius: 4px !important;
    height: 49px !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
    border: 1px solid #5956D6 !important;
} 

.Mui-completed .MuiStepConnector-line{
    border-color: #4CAF50 !important;
}
.Mui-active .MuiStepConnector-line{
    border-color: #4CAF50 !important;
}

.dashboard-stepper .Mui-active.MuiSvgIcon-root{
    color: #5956D6 !important;
}
.dashboard-stepper .step-completed .MuiSvgIcon-root{
    color: #008d3694 !important;
}
.dashboard-stepper .step-completed .Mui-completed.MuiSvgIcon-root, .dashboard-stepper .step-completed .Mui-active.MuiSvgIcon-root{
    color: #008D35 !important;
}


.dashboard-stepper .MuiStepLabel-label{
    margin-top: 0.25rem !important;
    font-weight: 400 !important;
}
.dashboard-stepper .Mui-active.MuiStepLabel-label{
    font-weight: 600 !important;
}

/* Tour component styles */

.reactour-helper{
    max-width: 350px !important;
    border-radius: 4px !important;
    padding: 16px !important;
}
.reactour-helper .tip-pointer-right {
    content: " ";
    position: absolute;
    left: 100%; 
    top: 50%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color:  transparent transparent transparent white;
}

.reactour-helper .tip-pointer-left {
    content: " ";
    position: absolute;
    right: 100%; 
    top: 50%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color:  transparent white transparent transparent ;
}

.button-small{
    padding: 4px 0px !important;
}
.simple-button{
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: 'Poppins' !important;
    line-height: 1.5rem !important;
    text-transform: none !important;
    border-radius: 4px !important;
    background-color: transparent !important;
    color: #5F6880 !important;
}

/* Simple Tooltip */

.simple-tooltip {
    position: relative;
    transition-duration: 0.2s;

  }
  
  .simple-tooltip .simple-tooltiptext {
    visibility: hidden;
    opacity: 0;
    display: inline-block;
    width: auto;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.65);
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    transform: translatex(-50%);
    transition-duration: 0.2s;
  }
  
  .simple-tooltip .simple-tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
  }
  
  .simple-tooltip:hover .simple-tooltiptext {
    visibility: visible;
    opacity: 100;
  }

/* Congratulations page styles */
.congrats-bg{
    background-position: top -50px right 0px;
    background-size: 60% auto;
    background-repeat: no-repeat;
}


.pink-gradient-border{
    position: relative;
    border: 4px solid transparent;
    border-radius: 16px;
    background: white;
    background-clip: padding-box;
}
/* Used z-index and absolute positioning to achieve gradient border */
.pink-gradient-border::after {
    position: absolute;
    top: -4px; bottom: -4px;
    left: -4px; right: -4px;
    background: linear-gradient(to right, #FFDCED, #FAF6FE, #EDF8FF, #FBFFF5, #EBEBFF);
    content: '';
    z-index: -1;
    border-radius: 16px;
}

.phone-design-container{
    max-width: 380px;
}

.phone-design{
    min-width: 333px;
    max-width: 380px;
    position: relative;
    width: 100%;
    overflow-x: hidden;
}


.phone-design .phone-background{
    display: inline-block;
    position: relative;
    width: 333px;
    height: 365px;
    overflow-y: hidden;
    background-repeat: no-repeat;
    background-size: 333px auto ;
    background-position: top left ;
    padding: 64px 27px;
}

.phone-design .phone-background .phone-inside{
    background-color: rgba(0, 0, 0, 0.25);
    background-repeat: no-repeat;
    background-size: 279px auto ;
    background-position: top left ;
    height: 304px;
}

.phone-inside *{
    font-family: Roboto;
}
.phone-inside .whatsapp-header{
    padding-top: 27px;
}

.phone-inside .whatsapp-header .whatsapp-icon{
    margin-left: 22px;
    margin-right: 8px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background-color: rgb(255, 199, 221);
    color: rgb(255, 57, 100);
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 12px;
}

.phone-inside .wa-messages .wa-message {
    color: #000;
    clear: both;
    font-size: 13px;
    position: relative;
    max-width: 85%;
    word-wrap: break-word;
    border-radius: 10px;
    text-align: left;
    /* flex-wrap: wrap;
    align-items: end;
    justify-content: space-between; */
}
.wa-message .wa-text{
    position: relative;
    line-height: 16px;
}
.wa-message .metadata{
    color: #AAAAAA;
    font-size: 11px;
    padding-left: 8px;
    float: right;
    position: relative;
    bottom: -6px;
    right: -2px;
}
.wa-message .metadata .time{
    display: inline-block;
}
.wa-message.sent{
    background: #e1ffc7;
    float: right;
}
.wa-message.sent.msg-pointer{
    border-radius: 10px 0px 10px 10px;
}
.wa-message.sent.msg-pointer::after{
    content: '';
    border-style: solid;
    position: absolute;
    border-width: 0px 0 8px 8px !important;
    border-color: transparent transparent transparent #e1ffc7;
    top: 0px;
    right: -8px;
}
.wa-message.received{
    background: #fff;
    float: left;
}
.wa-message.received.msg-pointer{
    border-radius: 0px 10px 10px 10px;
}
.wa-message.received.msg-pointer::after{
    content: '';
    border-style: solid;
    position: absolute;
    border-width: 0px 8px 8px 0px !important;
    border-color: transparent #fff transparent transparent ;
    top: 0px;
    left: -8px;
}

.wa-message .contact-icon{
    width: 27px;
    height: 27px;
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block;
    background-color: rgb(255, 199, 221);
    color: rgb(255, 57, 100);
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 12px;
}

.phone-background .phone-inside-qr{
    background-repeat: no-repeat;
    background-size: 200px auto ;
    background-position: center center ;
    height: 304px;
}

  

@media screen and (max-width: 768px) {
    .phone-design-container{
        max-width: 100%;
    }
    .phone-design{
        display: inline-block;
    }
}

/* Landing page styles */

.landing-page.wave-bg{
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
}

.landing-page .step-count{
    font-size: 180px;
    font-family: "Sen";
    text-shadow: 0px 0px 50px rgba(89, 86, 214, 0.15);
}

@media screen and (max-width: 976px) {
    .landing-page .step-count{
        font-size: 140px;
    }
}